@import "../../lib/bootstrap/scss/bootstrap-flex";
@import "../../lib/colors";

.tabContainer {
	overflow: hidden;

	.tabs {
		position: relative;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-around;
		border-bottom: 1px solid $secondaryColor;

		.tab {
			display: block;
			padding: 1em;
			width: 100%;
			text-align: center;
			white-space: nowrap;
			cursor: pointer;
			border: 0;
			background-color: $colorWhite;
			.text {
				color: $secondaryColor;
				display: inline-block;
				vertical-align: middle;
				cursor: pointer;
				font-weight: 500;
				font-size: 1em;
				@include media-breakpoint-down(xs) {
					display: none;
				}
			}
			i.fa, i.mdi {
				color: $secondaryColor;
				font-size: 1,25em;
				vertical-align: middle;
				cursor: pointer;
			}
			svg {
				color: $secondaryColor;
				vertical-align: middle;
				margin-right: 12px;
				cursor: pointer;
				@include media-breakpoint-down(xs) {
					margin-right: unset;
				}
			}
			&.active .text,
			&.active i.fa,
			&.active i.mdi,
			&:hover {
				color: $primaryColor;
				text-decoration: none;
			}
			&.active svg {
				fill: $primaryColor;
				text-decoration: none;
			}
		}

		.indicator {
			position: absolute;
			bottom: -1px;
			height: 3px;
			background: $primaryColor;
		}
	}
}

.sectionsContainer {
	padding: 10px 0 0;

	.sections > .section {
		display: none;

		p {
			font-size: 18px;
			line-height: 1.2;
			margin-bottom: 25px;
		}

		&.active {
			display: block;
		}
	}
}
